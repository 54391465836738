export function toDays(minutes) {
    const hours = minutes / 60

    const day = Math.floor(hours / 24)
    const hour = Math.floor(hours % 24)
    const minute = Math.floor(minutes % 60)

    return (day ? day + ' д. ' : '') +
        (hour ? hour + ' ч. ' : '') +
        (minute ? minute + ' мин.' : '')
}