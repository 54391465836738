<template>
  <div>
    <h2>Мои заказы</h2>
    <Payment ref="payment"/>
    <b-col cols="12">
      <b-row>
        <b-col>
          <b-card class="mb-4 mt-4">
            <a v-b-toggle href="#order-filter" class="filter-title" @click.prevent>Фильтр заказов</a>
            <b-collapse id="order-filter">
              <b-form-group label="Маршрут" class="mt-4">
                <b-form-select :value="filters.routeId" @change="setFilterByKey('routeId', $event)">
                  <b-form-select-option :value="null">Все</b-form-select-option>
                  <b-form-select-option v-for="route in routes" :value="route.id" :key="route.id">
                    {{ route.number }} - {{ route.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group label="Перевозчик">
                <b-form-select :value="filters.carrierId" @change="setFilterByKey('carrierId', $event)">
                  <b-form-select-option :value="null">Все</b-form-select-option>
                  <b-form-select-option v-for="carrier in carriers" :value="carrier.id" :key="carrier.id">
                    {{ carrier.carrierName }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Начиная с даты" class="mb-0">
                      <b-form-datepicker locale="ru" label-no-date-selected="Выберите дату" :start-weekday="1" :value="filters.dateFrom" @input="setFilterByKey('dateFrom', $event)" reset-button/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Заканчивая датой" class="mb-0">
                      <b-form-datepicker locale="ru" label-no-date-selected="Выберите дату" :start-weekday="1" :value="filters.dateTo" @input="setFilterByKey('dateTo', $event)" reset-button/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group label="Время отправления" class="d-flex">
                <b-form-timepicker id="timepicker" label-no-time-selected="" :value="filters.time" @input="setFilterByKey('time', $event)" reset-button></b-form-timepicker>
              </b-form-group>
              <div class="d-flex">
                <b-button variant="primary" @click="load">Применить</b-button>
              </div>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="orders" :fields="fields" :small="true">
            <template #cell(actions)="data">
              <div class="text-left d-flex">
                <b-button size="sm" variant="primary" @click="openOrder(data.item.id)">Управление</b-button>
              </div>
            </template>
            <template #cell(status)="data">
              <span v-if="data.value === 'booking'" class="status bg-danger">Забронировано</span>
              <span v-else-if="data.value === 'removed'" class="status bg-secondary">Удалено</span>
              <span v-else-if="data.value === 'paid'" class="status bg-success">Оплачено</span>
              <span v-else-if="data.value === 'expired'" class="status bg-secondary">Вышло время бронирования</span>
              <span v-else-if="data.value === 'prepared_booking'" class="status bg-primary">Активная бронь</span>
            </template>
          </b-table>
          <b-pagination-nav
              :link-gen="linkGen"
              :value="page"
              @page-click="load"
              :number-of-pages="maxPages ? maxPages : 1"
              use-router
          ></b-pagination-nav>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import {toRubles} from "@/utils/money";
import Payment from "@/components/blocks/Payment.vue";

export default {
  name: "WorkWithOrders",
  components: {Payment},
  data() {
    return {
      currentPage: 1,
      errors: {},
      fields: [
        {
          key: 'number',
          label: 'Номер заказа'
        },
        {
          key: 'clientPhone',
          label: 'Телефон'
        },
        {
          key: 'createdAt',
          label: 'Продажа',
          formatter: value => moment(value).format('DD.MM.YYYY HH:mm')
        },
        {
          key: 'trip.route.name',
          label: 'Рейс',
        },
        {
          key: 'trip.route.name',
          label: 'Направление'
        },
        {
          key: 'status',
          label: "Статус"
        },
        {
          key: 'seatsCount',
          label: "Метод оплаты"
        },
        {
          key: 'pay',
          label: "Кассир"
        },
        {
          key: 'amount',
          label: "Сумма",
          formatter: (value) => toRubles(value)
        },
        {
          key: 'freeSeatsCount',
          label: "Возврат"
        },
        {
          key: 'actions',
          label: ""
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('MyAllBooks/load', { page: 1 })
  },
  methods: {
    setFilterByKey(key, value) {
      this.$store.commit('MyAllBooks/SET_FILTER_BY_KEY', { key, value })
    },
    setSearchByKey(key, value) {
      this.$store.commit('MyAllBooks/SET_SEARCH_BY_KEY', { key, value })
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    load(event, page) {
      event.preventDefault()
      this.$store.dispatch('MyAllBooks/load', { page })
    },
    openOrder(orderId) {
      this.$refs.payment.showOrder(orderId)
    }
  },
  computed: {
    ...mapState({
      loading: state => state.MyAllBooks.loading,
      orders: state => state.MyAllBooks.items,
      filters: state => state.MyAllBooks.filters,
      page: state => state.MyAllBooks.page,
      maxPages: state => state.MyAllBooks.maxPages,

      carriers: state => state.Carriers.items,
      routes: state => state.Routes.items,
    })
  }
}
</script>

<style scoped>
.status {
  font-size: 12px;
  padding: 2px 5px;
  color: #fff;
  border-radius: 3px;
}
.filter-title {
  font-size: 16px;
  font-weight: 600;
  color: #3e5569;
}
</style>